import * as React from 'react';
import Layout from '../components/Layout';
import notFound from '../img/design/404.png';

const NotFoundPage = () => (
    <Layout>
        <section className="section section--salmon section--with-mask mask-four">
            <div className="container">
                <section className="section">
                    <div className="sitteroo-blog-post has-text-centered p-5">
                        <img src={notFound} alt="" />
                        <span className="is-sr-only">Route not found</span>
                        <p className="has-text-centered sitteroo-blog-list-heading--pastel mt-2">
                            Not too worry
                        </p>
                        <p className="has-text-centered sitteroo-blog-list-heading--pastel mt-2">
                            You can still head over to our{' '}
                            <a href="/">home page</a> or check out{' '}
                            <a href="/blog">blog</a>
                        </p>
                    </div>
                </section>
            </div>
        </section>
    </Layout>
);

export default NotFoundPage;
